import React, { useRef, useState, useEffect } from "react"
import LocalizedLink from "./localizedLink"

const Button = (props) => {

  const [style, setStyle] = useState({})

  const buttonRef = useRef()
  const innerButtonRef = useRef()

  let button = <button className={props.className} > {props.children} </button>


  if(typeof props.onClick !== "undefined"){
    button = <button ref={buttonRef} className={props.className} onClick={props.onClick} > <span style={style} id={props.id} className="text-nowrap d-inline-block" ref={innerButtonRef}>{props.children}</span> </button>
    //prevent duplicate id
    if(typeof props.href !== "undefined"){
      button = <button ref={buttonRef} className={props.className} onClick={props.onClick} > <span style={style} className="text-nowrap d-inline-block" ref={innerButtonRef}>{props.children}</span> </button>
    }
  }

  if(typeof props.href !== "undefined"){
    let isExternalSite = /:\/\//.test(props.href)

    button =  isExternalSite ? <a href={props.href} id={props.id} target="_blank" rel="noopener noreferrer"> {button} </a> : <LocalizedLink to={props.href} id={props.id}> {button} </LocalizedLink>

  }

  useEffect(() => {
  	if(props.panel){
  		let parentWidth = props.panel.current.clientWidth
  		if(parentWidth !== 0){
	  		let width = buttonRef.current.clientWidth
		  	if(width > parentWidth-30){
		  		let fs = window.getComputedStyle(innerButtonRef.current, null).getPropertyValue('font-size');
		  		fs = parseFloat(fs)
		  		let newFs = fs-0.5 + "px"
		  		setStyle({
		  			fontSize:newFs
		  		})
		  	}
  		}
  	}
  }, [style, props.children])

  return button
}

export default Button

import React from "react"
import useTranslations from "../../utils/useTranslations"
import { CompatibilityContext } from "../../components/compatibilityContext";
import Logo from "../../components/logo"
import Button from "../../components/button"
import heroImg from "../../images/token/btt/token.svg"
import ChimpForm from "../../components/chimpForm"
import LocalizedLink from "../../components/localizedLink"
import arrowBlack from "../../images/icons/arrow-black.svg"
import arrowWhite from "../../images/icons/arrow-white.svg"
import utClassicLogo from "../../images/token/btt/logo-ut-classic.svg"
import utWebLogo from "../../images/token/btt/logo-ut-web.png"
import btfsLogo from "../../images/logos/btfs-logo.svg"
import btfsImg from "../../images/token/bittorrent-file-system/ecosystem.svg"

import utSpeedImgWebp from "../../images/token/btt/speed-client.webp"
import utSpeedImg from "../../images/token/btt/speed-client.png"
import utSpeedImgWebp2x from "../../images/token/btt/speed-client@2x.webp"
import utSpeedImg2x from "../../images/token/btt/speed-client@2x.png"

import step1ImgWebp from "../../images/token/bittorrent-speed/step-1.webp"
import step1Img from "../../images/token/bittorrent-speed/step-1.png"
import step1ImgWebp2x from "../../images/token/bittorrent-speed/step-1@2x.webp"
import step1Img2x from "../../images/token/bittorrent-speed/step-1@2x.png"

import step2ImgWebp from "../../images/token/bittorrent-speed/step-2.webp"
import step2Img from "../../images/token/bittorrent-speed/step-2.png"
import step2ImgWebp2x from "../../images/token/bittorrent-speed/step-2@2x.webp"
import step2Img2x from "../../images/token/bittorrent-speed/step-2@2x.png"

import step3ImgWebp from "../../images/token/bittorrent-speed/step-3.webp"
import step3Img from "../../images/token/bittorrent-speed/step-3.png"
import step3ImgWebp2x from "../../images/token/bittorrent-speed/step-3@2x.webp"
import step3Img2x from "../../images/token/bittorrent-speed/step-3@2x.png"

import backgrounds from "../../components/backgrounds"
import q55 from "../../images/token/btt/exchange-logos/55.png"
import ABCC from "../../images/token/btt/exchange-logos/ABCC.png"
import AnyBit from "../../images/token/btt/exchange-logos/AnyBit.png"
import AtomicWallet from "../../images/token/btt/exchange-logos/AtomicWallet.png"
import Bibox from "../../images/token/btt/exchange-logos/Bibox.png"
import BigOne from "../../images/token/btt/exchange-logos/BigOne.png"
import BikiCoin from "../../images/token/btt/exchange-logos/BikiCoin.png"
import Binance from "../../images/token/btt/exchange-logos/Binance.png"
import Bitz from "../../images/token/btt/exchange-logos/Bit-z.png"
import BitMart from "../../images/token/btt/exchange-logos/BitMart.png"
import BitMax from "../../images/token/btt/exchange-logos/BitMax.png"
import Bitbns from "../../images/token/btt/exchange-logos/Bitbns.png"
import Bitexbook from "../../images/token/btt/exchange-logos/Bitexbook.png"
import Bitfinex from "../../images/token/btt/exchange-logos/Bitfinex.png"
import Bitforex from "../../images/token/btt/exchange-logos/Bitforex.png"
import Bithumb from "../../images/token/btt/exchange-logos/Bithumb.png"
import Bitpie from "../../images/token/btt/exchange-logos/Bitpie.png"
import Bitrue from "../../images/token/btt/exchange-logos/Bitrue.png"
import Bittrex from "../../images/token/btt/exchange-logos/Bittrex.png"
import ChangeNow from "../../images/token/btt/exchange-logos/ChangeNow.png"
import Changelly from "../../images/token/btt/exchange-logos/Changelly.png"
import Cobo from "../../images/token/btt/exchange-logos/Cobo.png"
import CoinAll from "../../images/token/btt/exchange-logos/CoinAll.png"
import Coinegg from "../../images/token/btt/exchange-logos/Coinegg.png"
import Coinex from "../../images/token/btt/exchange-logos/Coinex.png"
import CoinexMarket from "../../images/token/btt/exchange-logos/CoinexMarket.png"
import Coinspot from "../../images/token/btt/exchange-logos/Coinspot.png"
import Coinsuper from "../../images/token/btt/exchange-logos/Coinsuper.png"
import Cointiger from "../../images/token/btt/exchange-logos/Cointiger.png"
import Coinwai from "../../images/token/btt/exchange-logos/Coinwai.png"
import Cross from "../../images/token/btt/exchange-logos/Cross.png"
import DigiFinex from "../../images/token/btt/exchange-logos/DigiFinex.png"
import DragonEX from "../../images/token/btt/exchange-logos/DragonEX.png"
import DragoneEX from "../../images/token/btt/exchange-logos/DragoneEX.png"
import Ellipal from "../../images/token/btt/exchange-logos/Ellipal.png"
import ExShell from "../../images/token/btt/exchange-logos/ExShell.png"
import Exrates from "../../images/token/btt/exchange-logos/Exrates.png"
import Fcoin from "../../images/token/btt/exchange-logos/Fcoin.png"
import Hitbtc from "../../images/token/btt/exchange-logos/Hitbtc.png"
import Huobi from "../../images/token/btt/exchange-logos/Huobi.png"
import Indodax from "../../images/token/btt/exchange-logos/Indodax.png"
import Koineks from "../../images/token/btt/exchange-logos/Koineks.png"
import Koinex from "../../images/token/btt/exchange-logos/Koinex.png"
import Kucoin from "../../images/token/btt/exchange-logos/Kucoin.png"
import MBAEX from "../../images/token/btt/exchange-logos/MBAEX.png"
import MXC from "../../images/token/btt/exchange-logos/MXC.png"
import math from "../../images/token/btt/exchange-logos/Math.png"
import OKEx from "../../images/token/btt/exchange-logos/OKEx.png"
import Sato from "../../images/token/btt/exchange-logos/Sato.png"
import SatoWallet from "../../images/token/btt/exchange-logos/SatoWallet.png"
import Sistemkoin from "../../images/token/btt/exchange-logos/Sistemkoin.png"
import Swft from "../../images/token/btt/exchange-logos/Swft.png"
import TOKENOMY from "../../images/token/btt/exchange-logos/TOKENOMY.png"
import TRX_Market from "../../images/token/btt/exchange-logos/TRX_Market.png"
import TronCard from "../../images/token/btt/exchange-logos/TronCard.png"
import TronLink from "../../images/token/btt/exchange-logos/TronLink.png"
import TronWallet from "../../images/token/btt/exchange-logos/TronWallet.png"
import Tronscan from "../../images/token/btt/exchange-logos/Tronscan.png"
import TrustWallet from "../../images/token/btt/exchange-logos/TrustWallet.png"
import Upbit from "../../images/token/btt/exchange-logos/Upbit.png"
import Vena_Pi from "../../images/token/btt/exchange-logos/Vena_Pi.png"
import WazirX from "../../images/token/btt/exchange-logos/WazirX.png"
import banko from "../../images/token/btt/exchange-logos/banko.png"
import changehero from "../../images/token/btt/exchange-logos/changehero.png"
import exmo1 from "../../images/token/btt/exchange-logos/exmo1.png"
import gateio from "../../images/token/btt/exchange-logos/gate-io.png"
import image_banner from "../../images/token/btt/exchange-logos/image_banner.png"
import livecoin from "../../images/token/btt/exchange-logos/livecoin.png"

import { navigate } from "gatsby"

const style = {
  btfsImg:{
    backgroundImage:`url(${btfsImg})`,
    backgroundRepeat:`no-repeat`,
  }
}

const heroImgAlt = "BitTorrent Token Logo"
const utSpeedImgAlt = "BitTorrent Speed"
const utClassicLink = "https://www.utorrent.com/downloads/complete/track/stable/os/win" 
const utWebWinLink = "https://download-new.utorrent.com/endpoint/utweb/track/stable/os/win"  
const utWebMacLink = "https://utweb-assets.bittorrent.com/installer/uTorrentWeb.dmg" 
const btWebLink = "/downloads/complete"
 
class Token extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      isMac: false,
      showExchanges:false,
      showExchangesAndWallets:false
    }
  }

  componentDidMount(){

    this.setState({
      isMac:window.gatsbyIsMac,
    })

  }

  handleBtWebClick = (e) => {
    this.context(e, 'downloading', "Web", ["Win", "Osx"], btWebLink)
  }

  handleUtClassicClick = (e) => {
    this.context(e, 'downloading', "uTorrent Classic", ["Win"], utClassicLink)
  }

  handleUtWebClick = (e, link) => {
    this.context(e, 'downloading', "uTorrent Web", ["Win", "Osx"], link)
  }
  render(){

    const { text } = this.props.pageContext
    const t = useTranslations(text)

    const locale = this.props.pageContext.locale
    const googlePlayButton = `/images/google-play-badges/google-play-badge-${locale}.png`
    const appStoreButton = `/images/app-store-badges/app-store-badge-${locale}.svg`

    return (
      <>
        {/* Start - hero */}
        <div className="text-center text-md-left top-section-container p-0 pt-md-5 background-noise-dark" style={backgrounds.darkBg}>

          <div className="container  py-2">

            <div className="row mt-5 text-white">

              <div className="col-md-6 mt-md-3 py-md-3 order-md-1 px-md-0">
                <Logo color="white-inv" tag="h1" productName="Token" className="mb-4"/>
                <img src={heroImg} alt={heroImgAlt} className="img-fluid d-block d-md-none mb-4"></img>
                <div className="text-size-24 text-spacing-1 font-weight-normal">
                  {t(`Tokenizing the world’s largest decentralized file sharing protocol with BTT.`)}
                </div>
                <div className="col-md-12 px-md-0 pt-4">
                  <p className="token-text">
                    {t(`BTT is a TRC-20 utility token based on the blockchain that powers features of the most popular decentralized protocols and applications in the world. DApps powered by BTT include BitTorrent Speed, BitTorrent File System, and others in the pipeline.`)}
                  </p>
                </div>
              </div>

              <div className="col-md-6 mb-5 order-md-2 text-center">
                <img src={heroImg} alt={heroImgAlt} className="img-fluid d-none d-md-inline token-hero-img"></img>
              </div>

            </div>
                
            <div className="col-12 text-center text-white text-spacing-1 my-5">
              <a id="token-btt-hero-dl-whitepaper" className="mx-2 text-white" href="/btt/btt-docs/BitTorrent_(BTT)_White_Paper_v0.8.7_Feb_2019.pdf" target="_blank" rel="noopener noreferrer">{t(`WHITEPAPER`)}</a> | <a id="token-btt-hero-blockchain-explorer" className="mx-2 text-white" href="http://bit.ly/2DNgeI0" target="_blank" rel="noopener noreferrer">{t(`BLOCKCHAIN EXPLORER`)}</a> | <a id="token-btt-hero-exchanges-wallets" className="mx-2 text-white" href="#exchanges">{t(`EXCHANGES & WALLETS`)}</a>
            </div>

          </div>
          <div className="token-mail-container text-center py-5">
            <p className="text-size-24">
              {t(`Sign up to receive BTT updates.`)}
            </p>
            <div className="col-md-4 offset-md-4 mt-4 px-5">
              <ChimpForm
                id="token-btt-hero-mailchimp" 
                list="https://bittorrent.us5.list-manage.com/subscribe/post?u=a3baea4e54ff8e8b235488c11&id=ea7f201ccb"
                classInput="token-mail-input"
                classButton="token-mail-button"
                buttonText={t(`SIGN UP`)}
                placeholder=""
              />
            </div>
            <p className="mb-0 text-size-14">
             {t(`By signing up you agree to our <LocalizedLink to='/legal/privacy-policy' className="text-dark">Privacy Policy</LocalizedLink>.`)}
            </p>
          </div>
        </div> 
        {/* End - Hero */}

        <div className="container-fluid">
          <div className="container px-0 pb-5 text-center text-md-left">

            {/* Start - Use Cases */}
            <div className="token-section-header">
              <p className="token-section-header-title">{t(`BTT Services`)}</p>
              <p className="token-section-header-subtitle">{t(`Learn how products use BitTorrent Token.`)}</p>
            </div>

            <div className="row" style={backgrounds.tokenBg}>
              <div className="col-md-6">
                <Logo color="black-speed" tag="h2" productName="Speed" className="mb-4"/>
                <p className="token-text token-use-cases-1">
                  {t(`Get faster download speeds and BTT token rewards.`)}
                </p>
                <p className="token-text">
                  {t(`BitTorrent Speed is available as a feature of BitTorrent and µTorrent Web, as well as µTorrent Classic.`)}
                </p>
                <p className="token-text">
                  {t(`With BitTorrent Speed enabled, downloaders will have the option of providing tokens to uploaders in exchange for faster download speed. By introducing an incentive of earning BTT tokens, uploaders have a reason to seed files longer, and to dedicate more of their bandwidth and storage to other torrent clients in the network. BTT, which serves as a crypto torrent token in BitTorrent Speed, is responsible for powering a healthier BitTorrent protocol.`)}
                </p>
                <LocalizedLink
                id="token-btt-usecases-speed-learnmore" 
                className="text-dark text-decoration-none feature-list-learn-more index-mobile-learn-more " 
                to="/token/bittorrent-speed">
                  {t(`Learn more`)}
                </LocalizedLink>
                <img src={arrowBlack} className="ml-2 feature-list-arrow-black" alt="learn more"></img>
              </div>
              <div className="col-6 text-center d-flex justify-content-center">
                {/*<img src={utSpeedImg} alt={utSpeedImgAlt} className="img-fluid d-none d-md-inline align-self-center"></img>*/}

                <picture>
                  <source srcSet={`${utSpeedImgWebp} 1x, ${utSpeedImgWebp2x} 2x`} type="image/webp"></source>
                  <img srcSet={`${utSpeedImg} 1x, ${utSpeedImg2x} 2x`} src={utSpeedImg} className="img-fluid d-none d-md-inline align-self-center" alt={utSpeedImgAlt}></img>
                </picture>

              </div>
            </div>
            {/* End - Use Cases */}
          </div>
        </div>

        <div className="container-fluid background-noise-light" style={backgrounds.lightBg}>
          <div className="container px-0 pb-5">

            {/* Start - How it Works */}
            <div className="token-section-header">
              <p className="token-section-header-title">{t(`How it Works`)}</p>
              <p className="token-section-header-subtitle">{t(`Enable BTT and enjoy faster download speeds.`)}</p>
            </div>

            <div className="row mb-5 justify-content-between">
              <div className="col-12 col-md-5 offset-md-1 token-text">
                <p className="token-text text-size-24 font-weight-bold">{t(`Step 1 - Enable your wallet`)}</p>
                <p className="token-text">{t(`When downloading or upgrading to a new version of BitTorrent or µTorrent Web, or µTorrent Classic, the torrent software will be enabled with a wallet and BTT token balance.`)}</p>
              </div>
              <div className="col-12 col-md-5 text-center pl-md-0 text-md-left">
                {/*<img src={step1Img} alt="" className="img-fluid d-md-inline shadow-lg"></img>*/}

                <picture>
                  <source srcSet={`${step1ImgWebp} 1x, ${step1ImgWebp2x} 2x`} type="image/webp"></source>
                  <img srcSet={`${step1Img} 1x, ${step1Img2x} 2x`} src={step1Img} alt="" className="img-fluid d-md-inline shadow-lg wallet-steps-img"></img>
                </picture>

              </div>
            </div>

            <div className="row py-md-5 my-5 justify-content-between">
              <div className="col-12 col-md-5  token-text order-1 order-md-2">
                <p className="token-text text-size-24 font-weight-bold">{t(`Step 2 - Enjoy faster download speeds`)}</p>
                <p className="token-text">{t(`When downloading torrents, BitTorrent Speed automatically bids BitTorrent (BTT) to other users for faster speeds. Just use the torrent program like normal, there is nothing else you need to learn or do.`)}</p>
              </div>
              <div className="col-12 col-md-5 offset-md-1 text-center pl-md-0 text-md-left order-2 order-md-1">
                {/*<img src={step2Img} alt="" className="img-fluid d-md-inline shadow-lg"></img>*/}

                <picture>
                  <source srcSet={`${step2ImgWebp} 1x, ${step2ImgWebp2x} 2x`} type="image/webp"></source>
                  <img srcSet={`${step2Img} 1x, ${step2Img2x} 2x`} src={step2Img} alt="" className="img-fluid d-md-inline shadow-lg wallet-steps-img"></img>
                </picture>

              </div>
            </div>

            <div className="row my-5 justify-content-between">
              <div className="col-12 col-md-5 offset-md-1 token-text">
                <p className="token-text text-size-24 font-weight-bold">{t(`Step 3 - Earn BTT`)}</p>
                <p className="token-text">{t(`When a torrent file finishes downloading, you can automatically earn BitTorrent (BTT) by seeding. The integrated wallet allows for secure storage of your BTT, which you can send to other wallets.`)}</p>
              </div>
              <div className="col-12 col-md-5 text-center pl-md-0 text-md-left">
                {/*<img src={step3Img} alt="" className="img-fluid d-md-inline shadow-lg"></img>*/}

                <picture>
                  <source srcSet={`${step3ImgWebp} 1x, ${step3ImgWebp2x} 2x`} type="image/webp"></source>
                  <img srcSet={`${step3Img} 1x, ${step3Img2x} 2x`} src={step3Img} alt="" className="img-fluid d-md-inline shadow-lg wallet-steps-img"></img>
                </picture>

              </div>
            </div>
            {/* End - How it Works */}

          </div>
        </div>

        <div className="container px-0 pb-5">

          {/* Start - Get Started */}
          <div className="token-section-header">
            <p className="token-section-header-title">{t(`Get Started`)}</p>
            <h2 className="token-section-header-subtitle text-size-24 font-weight-normal">{t(`BitTorrent Speed enabled torrent clients.`)}</h2>
          </div>

          <div className="row mb-md-5 mx-0">
            <div className="col-12 col-md-4 text-center text-md-left">
              <Logo color="black" tag="h2" productName="Web" className="text-center text-md-left mb-4 mt-4 mt-md-0" fsMax={22}/>
              <p className="">{t(`An easy to use web torrent client that makes it simple to play files while they download.`)}</p>
              <Button
                id="token-btt-getstarted-download-btweb" 
                onClick = {this.handleBtWebClick} 
                href={btWebLink}
                className= {`btn-primary text-spacing-1 mt-0 mt-md-3 mb-4 button-filled text-spacing-1`}>
                  {t(`Free Download`)}
              </Button>
            </div>

            <div className="col-12 col-md-4 text-center text-md-left">
              <img className="img-fluid mb-4 mt-4 mt-md-0 btt-ut-logos" src={utClassicLogo} alt=""></img>
              <p className="">{t(`A very tiny desktop torrent client with advanced features for expert users.`)}</p>
              <Button
                id="token-btt-getstarted-download-utclassic" 
                onClick = {this.handleUtClassicClick} 
                href={utClassicLink}
                className= {`btn-primary text-spacing-1 mt-0 mt-md-3 mb-4 button-filled text-spacing-1`}>
                  {t(`Free Download`)}
              </Button>
            </div>

            <div className="col-12 col-md-4 text-center text-md-left">
              <img className="img-fluid mb-4 mt-4 mt-md-0 btt-ut-logos"  src={utWebLogo} alt=""></img>
              <p className="">{t(`A web-based torrent client that enables fast downloads of any type of file.`)}</p>
              {
                !this.state.isMac &&

                <Button
                  id="token-btt-getstarted-download-utweb" 
                  onClick = {(e) => {this.handleUtWebClick(e, utWebWinLink)}} 
                  href={utWebWinLink}
                  className= {`btn-primary text-spacing-1 mt-0 mt-md-3 mb-4 button-filled text-spacing-1`}>
                    {t(`Free Download`)}
                </Button>
              }

              {
                this.state.isMac &&

                <Button
                  id="token-btt-getstarted-download-utweb" 
                  onClick = {(e) => {this.handleUtWebClick(e, utWebMacLink)}} 
                  href={utWebMacLink}
                  className= {`btn-primary text-spacing-1 mt-0 mt-md-3 mb-4 button-filled text-spacing-1`}>
                    {t(`Free Download`)}
                </Button>
              }
            </div>


          </div>

          {/* End - Get Started */}
        </div>

        {/* Start - BTFS */}
        <div className="container-fluid py-5 background-noise-dark" style={backgrounds.darkBg}>
          <div className="container d-flex pt-md-5 px-0 token-btfs-inner-container text-center text-md-left">
            <div className="row w-100">
              <div className="col-lg-6 py-5">
                <img className="img-fluid mb-4 btfs-logo" src={btfsLogo} alt=""></img>
                <p className="token-text-large text-white ">{t(`BTFS is both a protocol and network implementation that provides a p2p mechanism for storing and sharing digital content in a decentralized filing system.`)}</p>
                <p className="token-text text-white">{t(`Traditional file storage systems have many disadvantages, including centralization or control by a single entity, the ability for governments to censor, higher costs, and low fault tolerance.`)}</p>
                <p className="token-text text-white">{t(`BTFS is a decentralized file storage system supported by millions of BitTorrent user nodes.* By running on the blockchain, which has a Delegated proof of Stake method of processing blockchain transactions, BTFS addresses these limitations. BTFS represents the first decentralized storage system that can be used by decentralized application (DApp) developers. Soon, BTT will be introduced into the BTFS ecosystem to incentivize a fair and abundant file system.`)}</p>
                <LocalizedLink
                id="token-btt-btfs-learnmore" 
                className="text-white text-decoration-none feature-list-learn-more index-mobile-learn-more " 
                to="/token/bittorrent-file-system">
                  {t(`Learn more`)} 
                </LocalizedLink>
                <img src={arrowWhite} className="ml-2 feature-list-arrow-black" alt="learn more"></img>
                <p className="mt-5 text-white font-weight-bold">*Coming soon.</p>
              </div>
              <div className="col-lg-6 d-flex align-items-center justify-content-center justify-content-lg-end">
                <img className="img-fluid mb-4" src={btfsImg} alt=""></img>
              </div>
            </div>
          </div>
        </div>
        {/* End - BTFS */}

 
    <div className="container text-center pb-5 scroll-target" id="exchanges">
        <p className="token-section-header-title">{t(`Exchanges`)}</p>

        <p className="h2 text-size-32 font-weight-strong text-center mt-5 pt-5">{t(`Exchanges listing BitTorrent (BTT)`)}</p>

          <div className="row">
              <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-exchanges-logo-Binance" href="https://www.binance.com" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Binance} alt="Binance"/></a></div>
              <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-exchanges-logo-OKEx" href="https://www.okex.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={OKEx} alt="OKEx"/></a></div>
              <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-exchanges-logo-Bittrex" href="https://bittrex.com" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Bittrex} alt="Bittrex"/></a></div>
              <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-exchanges-logo-Huobi" href="https://www.huobiwallet.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Huobi} alt="Huobi"/></a></div>
              <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-exchanges-logo-Bithumb" href="https://www.bithumb.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Bithumb} alt="Bithumb"/></a></div>
              <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-exchanges-logo-Hitbtc" href="https://hitbtc.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Hitbtc} alt="Hitbtc"/></a></div>
              <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-exchanges-logo-Bitfinex" href="https://www.bitfinex.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Bitfinex} alt="Bitfinex"/></a></div>
              <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-exchanges-logo-Kucoin" href="https://www.kucoin.com" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Kucoin} alt="Kucoin"/></a></div>
              <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-exchanges-logo-Upbit" href="https://upbit.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Upbit} alt="Upbit"/></a></div>
              <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-exchanges-logo-gateio" href="https://www.gate.io/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={gateio} alt="gateio"/></a></div>
              <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-exchanges-logo-Cointiger" href="https://www.cointiger.com" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Cointiger} alt="Cointiger"/></a></div>
              <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-exchanges-logo-Bitbns" href="https://bitbns.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Bitbns} alt="Bitbns"/></a></div>
                
          </div>
          { !this.state.showExchanges &&

            <p
              id="token-btt-exchanges-seemore"
              className="pointer mt-3 d-inline-block font-weight-bold" 
              onClick={()=>{ 
                this.setState({showExchanges:true})
              }}
              >
              See more {'>'}
            </p>
          }

          { this.state.showExchanges &&

            <div className="row">
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-exchanges-logo-TRX_Market" href="https://trx.market" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={TRX_Market} alt="TRX_Market"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-exchanges-logo-Coinspot" href="https://www.coinspot.com.au" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Coinspot} alt="Coinspot"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-exchanges-logo-Sistemkoin" href="https://sistemkoin.com/#/ " target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Sistemkoin} alt="Sistemkoin"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-exchanges-logo-Exrates" href="https://exrates.me" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Exrates} alt="Exrates"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-exchanges-logo-SatoWallet" href="https://www.satowallet.com" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={SatoWallet} alt="SatoWallet"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-exchanges-logo-Coinex" href="https://www.coinex.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Coinex} alt="Coinex"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-exchanges-logo-ABCC" href="https://abcc.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={ABCC} alt="ABCC"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-exchanges-logo-MBAEX" href="https://www.mbaex.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={MBAEX} alt="MBAEX"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-exchanges-logo-CoinexMarket" href="https://www.coinexmarket.io/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={CoinexMarket} alt="CoinexMarket"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-exchanges-logo-Bitforex" href="https://www.bitforex.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Bitforex} alt="Bitforex"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-exchanges-logo-Changelly" href="https://changelly.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Changelly} alt="Changelly"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-exchanges-logo-Cross" href="https://www.crossexchange.io" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Cross} alt="Cross"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-exchanges-logo-DigiFinex" href="https://www.digifinex.com" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={DigiFinex} alt="DigiFinex"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-exchanges-logo-Bitpie" href="https://bitpie.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Bitpie} alt="Bitpie"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-exchanges-logo-BikiCoin" href="https://www.biki.com" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={BikiCoin} alt="BikiCoin"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-exchanges-logo-Swft" href="https://www.swft.pro/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Swft} alt="Swft"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-exchanges-logo-Koinex" href="https://koinex.in" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Koinex} alt="Koinex"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-exchanges-logo-CoinAll" href="https://www.coinall.com" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={CoinAll} alt="CoinAll"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-exchanges-logo-q55" href="https://us.55.com" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={q55} alt="q55"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-exchanges-logo-BitMax" href="https://bitmax.io" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={BitMax} alt="BitMax"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-exchanges-logo-BitMart" href="https://www.bitmart.com" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={BitMart} alt="BitMart"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-exchanges-logo-ExShell" href="https://www.exshell.com" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={ExShell} alt="ExShell"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-exchanges-logo-AnyBit" href="https://www.anybit.io" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={AnyBit} alt="AnyBit"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-exchanges-logo-changehero" href="https://changehero.io" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={changehero} alt="changehero"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-exchanges-logo-banko" href="https://www.bankowallet.com/pc.html" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={banko} alt="banko"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-exchanges-logo-BigOne" href="https://b1.run/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={BigOne} alt="BigOne"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-exchanges-logo-exmo1" href="https://exmo.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={exmo1} alt="exmo1"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-exchanges-logo-TrustWallet" href="https://trustwallet.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={TrustWallet} alt="TrustWallet"/></a></div>

            </div>
          }
            <p className="h2 text-size-32 font-weight-strong text-center mt-5">{t(`Exchanges and wallets supporting BitTorrent (BTT) airdrop program`)}</p>

            <div className="row">
              <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-Bitpie" href="https://bitpie.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Bitpie} alt="Bitpie"/></a></div>
              <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-Cobo" href="https://cobo.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Cobo} alt="Cobo"/></a></div>
              <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-Bibox" href="https://www.bibox.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Bibox} alt="Bibox"/></a></div>
              <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-Cointiger" href="https://www.cointiger.com" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Cointiger} alt="Cointiger"/></a></div>
              <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-MBAEX" href="https://www.mbaex.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={MBAEX} alt="MBAEX"/></a></div>
              <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-Coinex" href="https://www.coinex.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Coinex} alt="Coinex"/></a></div>
              <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-Fcoin" href="https://www.fcoin.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Fcoin} alt="Fcoin"/></a></div>
              <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-WazirX" href="https://wazirx.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={WazirX} alt="WazirX"/></a></div>
              <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-Coinegg" href="https://www.coinegg.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Coinegg} alt="Coinegg"/></a></div>
              <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-Ellipal" href="https://www.ellipal.com" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Ellipal} alt="Ellipal"/></a></div>
              <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-Bitforex" href="https://www.bitforex.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Bitforex} alt="Bitforex"/></a></div>
              <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-DragoneEX" href="https://dragonex.io/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={DragoneEX} alt="DragoneEX"/></a></div>
            </div>

            { !this.state.showExchangesAndWallets &&

              <p
                id="token-btt-wallets-seemore"
                className="pointer mt-3 d-inline-block font-weight-bold" 
                onClick={()=>{ 
                  this.setState({showExchangesAndWallets:true})
                }}
                >
                See more {'>'}
              </p>
            }
            { this.state.showExchangesAndWallets &&

              <div className="row">
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-Binance" href="https://www.binance.com" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Binance} alt="Binance"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-OKEx" href="https://www.okex.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={OKEx} alt="OKEx"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-Bittrex" href="https://bittrex.com" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Bittrex} alt="Bittrex"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-Huobi" href="https://www.huobiwallet.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Huobi} alt="Huobi"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-Bithumb" href="https://www.bithumb.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Bithumb} alt="Bithumb"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-Hitbtc" href="https://hitbtc.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Hitbtc} alt="Hitbtc"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-Bitfinex" href="https://www.bitfinex.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Bitfinex} alt="Bitfinex"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-Kucoin" href="https://www.kucoin.com" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Kucoin} alt="Kucoin"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-Upbit" href="https://upbit.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Upbit} alt="Upbit"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-gateio" href="https://www.gate.io/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={gateio} alt="gateio"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-Changelly" href="https://changelly.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Changelly} alt="Changelly"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-TrustWallet" href="https://trustwallet.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={TrustWallet} alt="TrustWallet"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-Tronscan" href="https://tronscan.org/#/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Tronscan} alt="Tronscan"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-Vena_Pi" href="https://vena.network/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Vena_Pi} alt="Vena_Pi"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-Bitrue" href="https://www.bitrue.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Bitrue} alt="Bitrue"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-ABCC" href="https://abcc.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={ABCC} alt="ABCC"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-TOKENOMY" href="https://www.tokenomy.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={TOKENOMY} alt="TOKENOMY"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-Coinsuper" href="https://www.coinsuper.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Coinsuper} alt="Coinsuper"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-livecoin" href="https://www.livecoin.net" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={livecoin} alt="livecoin"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-Koinex" href="https://koinex.in" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Koinex} alt="Koinex"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-Bitz" href="https://www.bit-z.com" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Bitz} alt="Bitz"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-Indodax" href="https://indodax.com" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Indodax} alt="Indodax"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-MXC" href="https://www.mxc.com" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={MXC} alt="MXC"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-Coinspot" href="https://www.coinspot.com.au" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Coinspot} alt="Coinspot"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-Bitbns" href="https://bitbns.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Bitbns} alt="Bitbns"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-Sistemkoin" href="https://sistemkoin.com/#/ " target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Sistemkoin} alt="Sistemkoin"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-AtomicWallet" href="https://atomicwallet.io/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={AtomicWallet} alt="AtomicWallet"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-Bitexbook" href="https://bitexbook.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Bitexbook} alt="Bitexbook"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-TronWallet" href="https://www.tronwallet.me/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={TronWallet} alt="TronWallet"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-Coinwai" href="https://coinw.ai/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Coinwai} alt="Coinwai"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-TronLink" href="https://chrome.google.com/webstore/detail/tronlink/ibnejdfjmmkpcnlpebklmnkoeoihofec" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={TronLink} alt="TronLink"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-Coinex" href="https://www.coinex.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Coinex} alt="Coinex"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-Cross" href="https://www.crossexchange.io" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Cross} alt="Cross"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-Koineks" href="https://koineks.com" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Koineks} alt="Koineks"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-CoinAll" href="https://www.coinall.com" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={CoinAll} alt="CoinAll"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-q55" href="https://us.55.com" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={q55} alt="q55"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-BitMax" href="https://bitmax.io" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={BitMax} alt="BitMax"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-TRX_Market" href="https://trx.market" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={TRX_Market} alt="TRX_Market"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-BikiCoin" href="https://www.biki.com" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={BikiCoin} alt="BikiCoin"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-DigiFinex" href="https://www.digifinex.com" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={DigiFinex} alt="DigiFinex"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-SatoWallet" href="https://www.satowallet.com" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={SatoWallet} alt="SatoWallet"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-Exrates" href="https://exrates.me" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={Exrates} alt="Exrates"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-BitMart" href="https://www.bitmart.com" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={BitMart} alt="BitMart"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-ExShell" href="https://www.exshell.com" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={ExShell} alt="ExShell"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-AnyBit" href="https://www.anybit.io" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={AnyBit} alt="AnyBit"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-TronCard" href="https://troncard.io" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={TronCard} alt="TronCard"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-changehero" href="https://changehero.io" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={changehero} alt="changehero"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-banko" href="https://www.bankowallet.com/pc.html" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={banko} alt="banko"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-math" href="https://www.mathwallet.org/en/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={math} alt="math"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-ChangeNow" href="https://changenow.io/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={ChangeNow} alt="ChangeNow"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-BigOne" href="https://b1.run/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={BigOne} alt="BigOne"/></a></div>
                <div className="col-4 col-md-2  px-md-4 pt-5"><a id="token-btt-wallets-logo-exmo1" href="https://exmo.com/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={exmo1} alt="exmo1"/></a></div>
         
              </div>
            }
    </div>


      </>
    )

  }
}


Token.contextType = CompatibilityContext;

export default Token